import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Signin = _resolveComponent("Signin")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!
  const _component_router_view = _resolveComponent("router-view")!

  return (!_ctx.isUserAuthenticated)
    ? (_openBlock(), _createBlock(_component_AuthLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_Signin)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}