
import { defineComponent, nextTick, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

import AuthLayout from '@/components/page-layouts/Auth.vue';
import Signin from '@/views/crafted/authentication/basic-flow/SignIn.vue';

import { initializeComponents } from "@/core/plugins/keenthemes";



export default defineComponent({
  name: "app",
  components: {
    AuthLayout,
    Signin
  },
  setup() {
    const store = useStore();
    const isUserAuthenticated = computed(() => {
      let ret = store.getters.isUserAuthenticated;
      console.log('App.vue isUserAuthenticated', ret);
      return ret;
    });

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });

    return {
      isUserAuthenticated
    };
  },
});
